import React, { useState, useContext, useRef, useEffect } from "react";
import useForm from "../../../../services/UseForm";
import '../../../../css/payment.css';
import '../../../../css/paymentBill.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { POSOrderContent } from "./posorder";
import { UserContext } from "../../../../App";
import logo from '../../../../img/logo/qrcafe.jpg';
import kip100000 from '../../../../img/kip/100000kip.jpg';
import kip10000 from '../../../../img/kip/10000kip.jpg';
import kip1000 from '../../../../img/kip/1000kip.jpg';
import kip20000 from '../../../../img/kip/20000kip.jpg';
import kip2000 from '../../../../img/kip/2000kip.jpg';
import kip50000 from '../../../../img/kip/50000kip.jpg';
import kip5000 from '../../../../img/kip/5000kip.jpg';
import kip500 from '../../../../img/kip/500kip.jpg';
import { useReactToPrint } from 'react-to-print';

axios.defaults.baseURL = API;

class ComponentToPrint extends React.PureComponent {
    render() {
        function currencyFormat(num) {
            if (num !== "") {
                if(num >= 0 || num <= 0){
                    return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                }
            }
        }
        const billDetails = this.props.BillDetails;
        const productList = this.props.TemProductList;
        const today = Date.now();
        const dateNow = (new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today));
        return (
            <div className="PaymentBillContent">
                <div className="imgQR">
                  <img className="logo" src={logo} />
                </div>
                <div className="BillHeader">
                    <div className="BillTitle">
                        <span>Mertrade</span>
                    </div>
                    <div className="BillNO">
                        <span>BillNO # {billDetails.BillNO}</span>
                    </div>
                    <div className="Cashier">
                        <span>Cashier: {billDetails.cashier}</span>
                    </div>

                    <div className="ShowDate">
                        <span>Date: {dateNow}</span>
                    </div>
                    <div className="LocationName">
                        <span>Location: {billDetails.LocationName}</span>
                    </div>
                </div>
                <div className="BillBody">
                    <div className="BodyHeader">
                        <span className="CountNumber">NO.</span>
                        <span className="ProductName">Price</span>
                        <span className="Qty">Qty</span>
                        <span className="Discount">Discount</span>
                        <span className="Price">Total</span>
                    </div>
                    <div className="HeadLine">
                        <div className="footer-line"></div>
                        <div className="footer-line"></div>
                    </div>
                    <div className="BodyDetails">
                        {
                            productList && productList.map((x, y) => {
                                return (
                                    <div className="Items" key={y}>
                                        <div className="Name">
                                            <span className="ProductName">{x.EngName}</span>
                                        </div>
                                        <div className="Detail">
                                            <div className="mds">
                                                <span className="NO">{(y + 1) }</span>
                                                <span className="Price">{currencyFormat(x.Retail_Price)}</span>
                                                <span className="Qty">{currencyFormat(x.Quantity)}</span>
                                                <span className="Discount">{currencyFormat(x.TotalDiscount)}</span>
                                                <span className="Price">{currencyFormat(Number(x.SubTotal) - Number(x.TotalDiscount))}</span>
                                            </div>
                                        </div>
                                        <div className="footer-line"></div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                <div className="BillFooter">
                    <div className="footer-line"></div>
                    <div className="subtotal">
                        <span>SubTotal:</span>
                        <strong>{currencyFormat(billDetails.SubTotal)}</strong>
                    </div>
                    <div className="discout">
                        <span>Discount:</span>
                        <strong>{currencyFormat(billDetails.TotalDiscount)}</strong>
                    </div>
                    <div className="nettotal">
                        <span>NetTotal:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="paymentType">
                        <span>PaymentType:</span>
                        <strong>{billDetails.PaymentType}</strong>
                    </div>
                    <div className="paidAmount">
                        <span>PaidAmount:</span>
                        <strong>{currencyFormat(billDetails.PaidAmount)}</strong>
                    </div>
                    {
                        billDetails.PaidMoreAmount > 0 && 
                        <div className="PaidMoreAmount">
                            <span>PaidMoreAmount:</span>
                            <strong>{currencyFormat(billDetails.PaidMoreAmount)}</strong>
                        </div>
                        
                    }
                    <div className="changeAmount">
                        <span>Change:</span>
                        <strong>{currencyFormat(billDetails.ChangeAmount)}</strong>
                    </div>
                    
                    <div className="footer-line"></div>
                    <div className="footer-line"></div>
                    <div className="TaxBeforeVat">
                        <span>Total Before VAT:</span>
                        <strong>{currencyFormat((Number(billDetails.NetTotal) - Number(billDetails.Tax) ))}</strong>
                    </div>
                    <div className="VAT">
                        <span>VAT(10%):</span>
                        <strong>{currencyFormat(billDetails.Tax)}</strong>
                    </div>
                    <div className="TotalTax">
                        <span>Total:</span>
                        <strong>{currencyFormat(billDetails.NetTotal)}</strong>
                    </div>
                    <div className="footer-line"></div>
                    <div className="Thankyou">
                        <span>Thank you!</span>
                    </div>
                </div>
            </div>
        );
    }
}

function Payment(props) {

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    const {setShowAlert, setErrorMessage, TempOrder, setTempOrder, PaymentTypeList, 
        showPayment, setShowPayment, subTotal, setSubTotal, TotalDiscount, setTotalDiscount, NetTotal, setNetTotal, Tax, setTax} = useContext(POSOrderContent);
    const {userID, LocationID, UserName, LocationName} = useContext(UserContext);
    const componentRef = useRef();

    const [TransactionID, setTransactionID] = useState("");
    const [PaymentTypeID, setPaymentTypeID] = useState();
    const [PaymentType, setPaymentType] = useState("");

    const [PaidAmount, setPaidAmount] = useState();
    const [PaidMoreAmount, setPaidMoreAmount] = useState();
    const [ChangeAmount, setChangeAmount] = useState(0);
    const [disblebtnpayment, setDisblebtnpayment] = useState(1);

    const [TotalPaidAmount, setTotalPaidAmount] = useState(0);
    const [PaidAmountByCurrency, setPaidAmountByCurrency] = useState(0);

    const [InputClick, setInputClick] = useState(0);

    function currencyFormat(num) {
        if (num !== "") {
            if(num >= 0 || num <= 0){
                return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            }
        } 
    }

    const CalculateChangeAmount = (paidAmount, PaidMoreAmount, TotalDiscount,) => {
        if (subTotal > 0) {
            let gPaidAmount = 0;
            let gPaidMoreAmount = 0;
            let gTotalDiscount = 0;
            if(paidAmount > 0){
                gPaidAmount = paidAmount;
            }
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if(TotalDiscount > 0)
            {
                gTotalDiscount=TotalDiscount;
            }
            var change = (Number(gPaidAmount) + Number(gPaidMoreAmount) + Number(gTotalDiscount)) - (Number(subTotal));
            var totalpaidAmount = (Number(gPaidAmount) + Number(gPaidMoreAmount));
            setTotalPaidAmount(totalpaidAmount);
            setChangeAmount(change);
        }
    }

    const handleInputChange = event => {
        const {value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidAmount(value.split(',').join(''));
                CalculateChangeAmount(value.split(',').join(''), PaidMoreAmount, TotalDiscount);
            }  
        } else {
            setPaidAmount();
            CalculateChangeAmount(0, PaidMoreAmount, TotalDiscount);
        }
    };

    const handleInputClick = (e) => {
        setInputClick(e);
    };

    const hanldeclickMoney = (money) => {
        if(!InputClick & PaymentType != "Member" & PaymentType != "MEMBER"){
            if(PaidAmount > 0){
                var totalpaidAmount = Number(PaidAmount) + Number(money);
                setPaidAmount(totalpaidAmount);
                CalculateChangeAmount(totalpaidAmount, PaidMoreAmount, TotalDiscount);
            } else{
                setPaidAmount(money);
                CalculateChangeAmount(money, PaidMoreAmount, TotalDiscount);
            }
        } else {
            if(PaidMoreAmount > 0){
                var totalpaidMoreAmount =Number(PaidMoreAmount) + Number(money);
                setPaidMoreAmount(totalpaidMoreAmount);
                CalculateChangeAmount(PaidAmount, totalpaidMoreAmount, TotalDiscount);
            } else {
                setPaidMoreAmount(money);
                CalculateChangeAmount(PaidAmount, money, TotalDiscount);
            }
        }
    }

    // const handleChangeDiscount = (event) => {
    //     const { value } = event.target;
    //     var tax = 0;
    //     const re = /^[0-9\b]+$/;
    //     if (value) {
    //         if (value === '' || re.test(value.split(',').join(''))) {
    //             var discount = Number(value.split(',').join(''))
    //             var NewNetTotal = Number(subTotal) - Number(discount);
    //             tax = (Number(subTotal) - ((Number(subTotal) * 100) /110)).toFixed(2);
    //             setTotalDiscount(discount);
    //             setNetTotal(NewNetTotal);
    //             setTax(tax);
    //             CalculateChangeAmount(PaidAmount, PaidMoreAmount, discount)
    //         }  
    //     } 
    //     else {
    //         tax = ((Number(subTotal) - (Number(subTotal) * 100) /107)).toFixed(2);
    //         setTotalDiscount(0);
    //         setNetTotal(subTotal);
    //         setTax(tax);
    //         CalculateChangeAmount(PaidAmount, PaidMoreAmount, 0)
    //     }
    // }

    const handleInputMoreCashChange = event => {
        const { value } = event.target;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (value === '' || re.test(value.split(',').join(''))) {
                setPaidMoreAmount(value.split(',').join(''));
                CalculateChangeAmount(PaidAmount, value.split(',').join(''), TotalDiscount);
            }  
        } else {
            setPaidMoreAmount();
            CalculateChangeAmount(PaidAmount, 0, TotalDiscount);
        }
    };

    const handleSelectPaymentType = (e) => {
        var data = PaymentTypeList.filter(function (item) {
            return item.PaymentType === e.target.value
        })
        if (data.length > 0) {
            setPaidMoreAmount();
            CalculateChangeAmount(PaidAmount, 0, TotalDiscount);
            setPaymentTypeID(data[0].PaymentTypeID);
            setPaymentType(data[0].PaymentType);
        } else {
            setPaymentTypeID("");
            setPaymentType("");
        }
    };

    async function saveTutorial() {
        if(!PaymentTypeID){
            setErrorMessage("ກະລຸນາເລືອກປະເພດຈ່າຍ");
            setShowAlert(true);
        } else {
            let gPaidAmount = 0;
            let gPaidMoreAmount = 0;
            if(PaidAmount > 0){
                gPaidAmount = PaidAmount;
            }
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if((Number(gPaidAmount) + Number(gPaidMoreAmount)) >= NetTotal){
                setDisblebtnpayment("")
                CreateTransaction();
            } else {
                setErrorMessage("ຍອດຈ່າຍບໍ່ພຽງພໍ");
                setShowAlert(true);
            }
        }
    };

    async function CreateTransaction(){
        let token = localStorage.getItem("token");
            let gPaidMoreAmount = 0;
            let gTotalDiscount = 0;
            if(PaidMoreAmount > 0){
                gPaidMoreAmount = PaidMoreAmount;
            } 
            if(TotalDiscount > 0)
            {
                gTotalDiscount=TotalDiscount;
            }
        var data = {
            SubTotal: subTotal,
            Discount: gTotalDiscount,
            NetTotal: NetTotal,
            PaidAmount: Number(TotalPaidAmount),
            PaidMoreAmount: gPaidMoreAmount,
            ChangeAmount: ChangeAmount,
            PaymentTypeID: PaymentTypeID,
            LocationID: LocationID,
            Tax: Number(Tax),
            CreatedByID: userID,
            CreatedDate:  getDate(),
            StatusID: 1,
        }
        await axios.post("/createTransaction", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            setTransactionID(response?.data?.insertId)
            handlePrint();
            CreateTransactionDetail(response?.data?.insertId);
            ClearData();
        }).catch((err) => {
            console.log(err);
        });
    }
 
    async function CreateTransactionDetail(TransactionID) {
        let token = localStorage.getItem("token");
        if(TempOrder.length > 0) {
            for (var i=0; i < TempOrder.length; i++) {
                var list;
                list ={
                    TransactionID: TransactionID,
                    ProductID: TempOrder[i].ProductID,
                    Quantity: TempOrder[i].Quantity,
                    Retail_Price: TempOrder[i].Retail_Price,
                    Discount: TempOrder[i].Discount,
                    SubTotal: TempOrder[i].SubTotal,
                    NetTotal: Number(TempOrder[i].SubTotal) - Number(TempOrder[i].TotalDiscount)
                };
                await axios.post("/createTransactionDetail", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                    getStoreInventory(TempOrder[i].ProductID, TempOrder[i].Quantity)
                    getConsignmentProductsQty(TempOrder[i].ProductID, TempOrder[i].Quantity);
                }).catch((err) => {
                    console.log(err);
                });
            } 
            // ClearData();
        }
    };

    async function getStoreInventory (ProductID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            LocationID: LocationID,
        }
        await axios.post("/getStoreInventory",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let inventoryData = response?.data;
            var gQuantity = 0;
            if(inventoryData.length > 0){
                gQuantity = Number(inventoryData[0].Quantity) - Number(Quantity);
                dropInventory(inventoryData[0].StoreInventoryID, gQuantity);
            } 
        }).catch((err) => {
            console.log(err);
        });
    }

    async function getConsignmentProductsQty (ProductID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            ProductID: ProductID,
            LocationID: LocationID,
        }
        await axios.post("/getConsignmentProductsQty",data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            let inventoryData = response?.data;
            var gQuantity = 0;
            if(inventoryData.length > 0){
                gQuantity = Number(inventoryData[0].Quantity) - Number(Quantity);
                dropConsignmentProductsQty(inventoryData[0].ConsignmentProductID, gQuantity);
            } 
        }).catch((err) => {
            console.log(err);
        });
    }
    
    async function dropInventory (StoreInventoryID, Quantity) {
        let token = localStorage.getItem("token");
        var data = {
            StoreInventoryID: StoreInventoryID,
            Quantity: Quantity,
        }
        await axios.post("/PosUpdateStoreInventory", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function dropConsignmentProductsQty (ConsignmentProductID , Quantity) {
        let token = localStorage.getItem("token");
        if(Quantity > 0){
            var data = {
                ConsignmentProductID : ConsignmentProductID ,
                Quantity: Quantity,
                StatusID: 1
            }
        } else {
            var data = {
                ConsignmentProductID : ConsignmentProductID ,
                Quantity: Quantity,
                StatusID: 2
            }
        }
        
        await axios.post("/PosUpdateConsignmentProductQty", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const ClearData = () => {
        const timeout = setTimeout(() => {
            setTransactionID("");
            setPaidAmount();
            setPaidMoreAmount();
            setTotalDiscount();
            setChangeAmount(0);
            setTotalPaidAmount(0);
            setPaidAmountByCurrency(0);
            setPaymentType("");
            setPaymentTypeID("");
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
            setShowPayment(false);
            setDisblebtnpayment(1);
            localStorage.removeItem(userID);
        }, 3000);
        return () => clearTimeout(timeout);
    }

    const onClickClose = () => {
        setTransactionID("");
        setPaidAmount();
        setPaidMoreAmount();
        setTotalDiscount();
        setChangeAmount(0);
        setTotalPaidAmount(0);
        setPaidAmountByCurrency(0);
        setShowPayment(false);
    }

    return (
        <div style={{ display: showPayment ? 'flex' : 'none' }} className="paymentForm">
            <div className="PaymentContents">
                <div className="leftConten">
                    <div className="inputAmout">
                        <div className="selectContent"> 
                            <div className="PaymentType">
                                <label>ປະເພດຈ່າຍ</label>
                                <select className="PaymentType" onChange={(e) => handleSelectPaymentType(e)}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກປະເພດຈ່າຍ'}
                                    </option>,
                                    {PaymentTypeList && PaymentTypeList.map(item => (
                                        <option
                                            key={item.PaymentTypeID}
                                            value={item.PaymentType}
                                        >
                                            {item.PaymentType}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="inputContent">
                            <div className="txtPaidAmount">
                                <label>ຍອດຈ່າຍ</label>
                                <input className={`input form-control`} name="PaidAmount" placeholder="ຈ່າຍ" required onClick={(e) => handleInputClick(0)} onChange={(e) => handleInputChange(e)} 
                                value={currencyFormat(PaidAmount)} disabled={PaymentType === "Gift Card" || PaymentType === "Member" || PaymentType === ""}  />
                                {/* {errors.PaidAmount && (
                                    <p className="invalid-feedback">{errors.PaidAmount}</p>
                                )} */}
                            </div>
                            <div className="txtPaidMore">
                                <label>ຈ່າຍເພີ່ມ</label>
                                <input className={`input form-control`} name="PaidMordAmount" placeholder="ຈ່າຍເພີ່ມເງິນສົດ" onClick={(e) =>handleInputClick(1)} required onChange={(e) => handleInputMoreCashChange(e)} 
                                value={currencyFormat(PaidMoreAmount)} disabled={!PaymentType || PaymentType === "Cash" || PaymentType === "CASH"} />
                                {/* {errors.PaidMoreAmount && (
                                    <p className="invalid-feedback">{errors.PaidMoreAmount}</p>
                                )} */}
                            </div>
                        </div>
                    </div>
                        <div className="inputMoneyContent">
                            <div className="group1">
                                <div className="money" onClick={(e) => hanldeclickMoney(500)}>
                                    <img src={kip500} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(1000)}>
                                    <img src={kip1000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(2000)}>
                                    <img src={kip2000} />
                                </div>
                            </div>
                            <div className="group2">
                                <div className="money" onClick={(e) => hanldeclickMoney(5000)}>
                                    <img src={kip5000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(10000)}>
                                    <img src={kip10000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(20000)}>
                                    <img src={kip20000} />
                                </div>
                            </div>
                            <div className="group3">
                                <div className="money" onClick={(e) => hanldeclickMoney(50000)}>
                                    <img src={kip50000} />
                                </div>
                                <div className="money" onClick={(e) => hanldeclickMoney(100000)}>
                                    <img src={kip100000} />
                                </div>
                            </div>
                        </div>
                    <div className="footer">

                    </div>
                </div>
                <div className="rightContent">
                    <div className="Header">
                        <div className="btnclose" onClick={e => onClickClose()}>X</div>
                    </div>
                    <div className="body">
                        <div className="subtotal">
                            <span>ຍອດລວມ:</span>
                            <strong>{currencyFormat(subTotal)}</strong>
                        </div>
                        <div className="discout">
                            <span>ສ່ວນຫລຸດ:</span>
                            <strong>{<input className={`input form-control`} name="Discount" placeholder="0" required  
                                value={currencyFormat(TotalDiscount)} disabled />}</strong> 
                        </div>
                        <div className="tax">
                            <span>Tax:</span>
                            <strong>{currencyFormat(Tax) || '0'}</strong>
                        </div>
                        <div className="nettotal">
                            <span>ຍອດລວມຕ້ອງຈ່າຍ:</span>
                            <strong>{currencyFormat(NetTotal) || '0'}</strong>
                        </div>
                        <div className="footer-line"></div>
                        <div className="paidAmount">
                            <span>ຍອດຈ່າຍ:</span>
                            <strong>{currencyFormat(PaidAmount) || '0'}</strong>
                        </div>
                        <div className="changeAmount">
                            <span>ເງິນທອນ:</span>
                            <strong>{currencyFormat(ChangeAmount) || '0'}</strong>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="btn-Payment">
                            <button className="btn" onClick={(e) => saveTutorial()} disabled={!disblebtnpayment}>ຈ່າຍເງິນ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'none'}}>
            <ComponentToPrint ref={componentRef}
                    TemProductList={TempOrder}
                    BillDetails={{"Queue": "testQueue", "BillNO": TransactionID, "cashier": UserName, 
                    "LocationName": LocationName, "PaymentType": PaymentType, "SubTotal": subTotal, "TotalDiscount": TotalDiscount, 
                    "Tax": Tax, "NetTotal": NetTotal, "PaidAmount": PaidAmount,"PaidMoreAmount": PaidMoreAmount, "ChangeAmount": ChangeAmount}} 
                />
            </div>
        </div >
    );
};

export default Payment;
