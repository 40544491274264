import React, { useState, useContext, createContext, useRef, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../services/api";
import '../../../../css/posOrder.css';
import logo from '../../../../img/logo/ManeeCafeLogo.jpg';
import { UserContext , AlertContext} from "../../../../App";
import Payment from "./payment";
import Alert from "../../../../components/alert";
const POSOrderContent = createContext();

axios.defaults.baseURL = API;

function PosOrder() {

    const {userID, UserName, showOrder, setShowOrder, LocationID, LocationName, Role} = useContext(UserContext);
    const {showAlert, setShowAlert, errorMessage, setErrorMessage} = useContext(AlertContext);
    const [TempOrder, setTempOrder] = useState([]);
    const [show, setShow] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [subTotal, setSubTotal] = useState([]);
    const [TotalDiscount, setTotalDiscount] = useState([]);
    const [Tax, setTax] = useState(0);
    const [NetTotal, setNetTotal] = useState([]);
    const [Barcode, setBarcode] = useState([]);
    const [Quantity, setQuantity] = useState(1);
    const [PaymentTypeList, setPaymentTypeList] = useState([]);
    const [CurrencyList, setCurrencyList] = useState([]);

    function currencyFormat(num) {
        if (num !== "" && num > 0) {
            return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
    }

    const handleInputChange = event => {
        setQuantity(event.target.value);
    };
    async function addOrder(ProductID, ProductType, Retail_Price, LaoName, EngName, qty, Discount) {
        var c =0;
        for (var i=0; i < TempOrder.length; i++) {
            if (TempOrder[i].ProductID == ProductID) {
                if(Number(TempOrder[i].Quantity) + Number(qty) > 0)
                {
                    TempOrder[i].Quantity = Number(TempOrder[i].Quantity) + Number(qty);
                    TempOrder[i].TotalDiscount = Number(TempOrder[i].Discount) * Number(TempOrder[i].Quantity);
                    TempOrder[i].SubTotal = Number(TempOrder[i].Quantity) * Number(TempOrder[i].Retail_Price);
                    
                } else {
                    TempOrder.splice(i, 1);
                }
                c = 1;
                break;
            }
        }  
        if(c == 0){
            var datalist = {
                SubTotal: Retail_Price,
                ProductID: ProductID,
                LaoName: LaoName,
                EngName: EngName,
                Retail_Price: Retail_Price,
                Discount: Discount,
                TotalDiscount: Discount,
                userID: userID,
                Quantity: Number(qty),
                StatusID : 1,
                PrintStatus: "N",
                ProductType: ProductType,
            }
            TempOrder.push(datalist);
        }
        localStorage.setItem(userID, JSON.stringify(TempOrder));
        getSubtotal();
    }

    async function handleSearchProduct (e) {
        let token = localStorage.getItem("token");
        setBarcode(e.target.value);
        if(Quantity > 0){
            if(e.target.value){
                var data = {
                    Barcode: e.target.value
                }
                await axios.post("/getProductByBarcode", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                    let productData = response?.data;
                    if(productData.length > 0){
                        addOrder(productData[0].ProductID, productData[0].ProductType, productData[0].Retail_Price, productData[0].LaoName, productData[0].EngName, Quantity, productData[0].Discount);
                        setBarcode("");
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }

    const getSubtotal = () => {
        let userData = localStorage.getItem(userID);
        if (userData) {
          let userRole = JSON.parse(userData);
          setTempOrder(userRole);
          if(userRole.length > 0){
                var gSubTotal = 0;
                var gTotalDiscount = 0;
                for (var i=0; i < userRole.length; i++) {
                    gSubTotal = Number(gSubTotal) + Number(userRole[i].SubTotal);
                    gTotalDiscount = Number(gTotalDiscount) + Number(userRole[i].TotalDiscount);
                }  
                setTotalDiscount(gTotalDiscount);
                setSubTotal(gSubTotal);
                setNetTotal(Number(gSubTotal) - Number(gTotalDiscount));
                var tax = (Number(gSubTotal) -((Number(gSubTotal) * 100) /107)).toFixed(2);
                setTax(tax);
            } else{
                setTotalDiscount(0);
                setSubTotal(0);
                setNetTotal(0);
                setTax(0);
            }
        } else{
            setTempOrder([]);
            setSubTotal(0);
            setNetTotal(0);
            setTax(0);
        }
    }


    async function createTemOrderWhenclickclose() {
        setShowOrder(false)
    };

    const handleShowPayment = () => {
       if(TempOrder.length > 0){
        setShowPayment(true);
       } else{
            setErrorMessage("ກະລຸນາເລືອກສິນຄ້າກ່ອນ");
            setShowAlert(true);
       }
    }

    useEffect(() => {
        getSubtotal();
        let paymentData = localStorage.getItem("paymentTypeData");
        if (paymentData) {
            let payment = JSON.parse(paymentData);
            setPaymentTypeList(payment);
        }
        let currencyData = localStorage.getItem("currencyData");
        if (currencyData) {
            let currency = JSON.parse(currencyData);
            setCurrencyList(currency);
        }
      }, []);

    return (
        <POSOrderContent.Provider value={{LocationID, PaymentTypeList, TempOrder, setTempOrder, CurrencyList, show, setShow, showPayment, setShowPayment, 
            showOrder, setShowOrder, subTotal, setSubTotal, TotalDiscount, setTotalDiscount, NetTotal, setNetTotal, 
            Tax, setTax, showAlert, setShowAlert, errorMessage, setErrorMessage}}>
        <div style={{ display: showOrder ? 'flex' : 'none' }} className="formPos">
            <div className="OrderContent">
            <Alert/> 
            <Payment show={showPayment}/>
                <div className="CenterContent">
                    <div className="CenterHeader">
                        <div className="user-name">{UserName}</div>
                        <div className="Search">
                            <div className="Barcode">
                                <span>Barcode</span>
                                <input type="text" className="form-control" placeholder="Search" onChange={(e) => handleSearchProduct(e)} value={Barcode} />
                            </div>
                            <div className="Qty">
                                <span>Quantity</span>
                                <input type="text" className="form-control"  onChange={(e) => handleInputChange(e)} value={Quantity}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="underLine">
                        <hr />
                        <span>{} Menu</span>
                    </div>
                </div>

                <div className="RightContent">
                    <div className="billNo">
                        <div className="ordertext"><p>Order List</p></div>

                        <div className="btnclose" onClick={e => createTemOrderWhenclickclose()} >X</div>
                    </div>
                    <div className="footer-line"></div>
                    <div className="OrderHead">
                        <div className="ProductName"><p>Price</p></div>
                        <div className="Quantity">Quantity</div>
                        <div className="Discount">Discount</div>
                        <div className="Price">Total</div>
                    </div>
                    <div className="footer-line"></div>
                    <div className="posRightSidenavContent">
                        {
                            TempOrder && TempOrder.map((x, y) => {
                                return (
                                    <div key={y} className="orderContent">
                                        <div className="orderFooter">
                                            <div className="price">
                                                <p>{currencyFormat(x.Retail_Price)}</p>
                                            </div>
                                            <div className="plus" onClick={() => addOrder(x.ProductID, x.ProductType, x.Retail_Price, x.LaoName, x.EngName, 1,  x.Discount)}>
                                                +
                                            </div>
                                            <div className="quantity">
                                                <p>{currencyFormat(x.Quantity)}</p>
                                            </div>
                                            <div className="minus" onClick={() => addOrder(x.ProductID, x.ProductType, x.Retail_Price, x.LaoName, x.EngName, -1, x.Discount)}>
                                                -
                                            </div>
                                            <div className="Discount">
                                                <p>{currencyFormat(x.TotalDiscount)}</p>
                                            </div>
                                            <div className="Subtotal">
                                                <p>{currencyFormat((x.Quantity * x.Retail_Price) - x.TotalDiscount)}</p>
                                            </div>
                                        </div>
                                        <div className="orderBody">
                                            <div className="detail">
                                                <div className="productName">
                                                    <span>{y+1}. {x.LaoName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="posRightSidenavBottom">
                        <div className="footer-subtotal">
                            <div className="footer-line"></div>
                            <div className="nettotal">
                                <span>Sutotal:</span>
                                <strong>{currencyFormat(subTotal)}</strong>
                            </div>
                        </div>
                        <div className="paymentMethod">
                            <div className="btnpayment" onClick={(e) => handleShowPayment()}>
                                <span>Payment</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </POSOrderContent.Provider>
    );
};
export {POSOrderContent};
export default PosOrder;
