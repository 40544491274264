import React, { useEffect, useState, useContext } from "react";
import useForm from "../../../../services/UseForm";
import '../../../../css/popUpForm.css';
import '../../../../css/temOrder.css';
import axios from "axios";
import { API } from "../../../../services/api";
import { StoreConsignmentProductsContext } from "../store_consignment_products";
axios.defaults.baseURL = API;

function TempConsignmentProductsByBarcode(props) {

    const {dataList, showData, setShowData} = useContext(StoreConsignmentProductsContext);

    const [ProductList, setProductList] = useState();

    const handleInputChange = event => {
        const { name, value } = event.target;
        if(value) 
            handleChange(name, value);
        else
            handleChange("Quantity", 1);
    };

    async function SearchProductByBarcode (event) {
        const { name, value } = event.target;
        let token = localStorage.getItem("token");
        if(value){
            handleChange(name, value);
            var data = {
                Barcode: value
            }
            await axios.post("/getProductByBarcode", data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
                let productData = response?.data;
                if(productData.length > 0){
                    setProductList(productData);
                } else {
                    setProductList([]);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            handleChange(name, "");
            setProductList([]);
        }
    }

    const addTemOrder = (ProductID, Barcode, LaoName, EngName, BuyPrice, ProductType) => {
        var c =0;
        for (var i=0; i < showData.length; i++) {
            if (showData[i].Barcode == Barcode) {
                showData[i].Quantity = Number(showData[i].Quantity) + Number(values.Quantity);
                showData[i].SubTotal = Number(showData[i].Quantity) * Number(showData[i].BuyPrice);
                localStorage.setItem("purchaseOrderData", JSON.stringify(showData));
                c = 1;
                break;
            }
        }  
        if(c === 0){
            var SubTotal = BuyPrice;
            if(values.Quantity > 1){
                SubTotal = Number(values.Quantity) * Number(BuyPrice);
            }
            var list = {
                ProductID: ProductID,
                Barcode: Barcode,
                EngName: EngName,
                LaoName: LaoName,
                BuyPrice: BuyPrice,
                Quantity: values.Quantity,
                SubTotal: SubTotal,
                ProductType: ProductType,
            }
            showData.push(list);
            localStorage.setItem("purchaseOrderData", JSON.stringify(showData));
        }
        setOrderList();
    }

    const setOrderList = () => {
        let userData = localStorage.getItem("purchaseOrderData");
        if (userData) {
            let userRole = JSON.parse(userData);
            setShowData(userRole);
            setProductList([]);
        } else
            setShowData([]);
    }

    const {
        values,
        handleChange,
    } = useForm();

    useEffect(() => {
        handleChange("Quantity", 1);
    }, []);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="TempForm">
                <div className="txtSearch">
                        <div className="txt">
                            <span>ຄົ້ນຫາດ້ວຍ Barcode</span>
                            <input className="input form-control"  type="text" name="Barcode" placeholder="ຄົ້ນຫາ" onChange={(e) => SearchProductByBarcode(e)} value={values.Barcode} />
                        </div>
                        <div className="Quantity">
                            <span>ຈຳນວນ</span>
                            <input className="input form-control"  type="text" name="Quantity" placeholder="1" onChange={(e) => handleInputChange(e)} value={values.Quantity || ''} required />
                        </div>
                        <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger btnBack" data-dismiss="modal" aria-label="Close">
                            ກັບຄືນ
                        </button>
                </div>
                <div className="TempContent">
                    {
                        ProductList && ProductList.map((x, y) => {
                            return (
                                <div key={y} className="TempItem" >
                                    <div className="TempBody">
                                        <div className="productName">
                                            <span>{(y+1) + '.'} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {x.LaoName}</span>
                                        </div>
                                        <div className="btnAdd"> 
                                            <button className="btn btn-success btnAdd" onClick={() => addTemOrder(x.ProductID, x.Barcode, x.LaoName, x.EngName, x.BuyPrice, x.ProductType )} >ເພີ່ມ</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        </div>
    );
};

export default TempConsignmentProductsByBarcode;
