import React, { useContext, useState } from "react";
import useForm from "../../../../services/UseForm";
import validate from '../../../../validations/admin/stock/CreateStoreConsignmentProductsValidate';
import '../../../../css/popUpForm.css';
import axios from "axios";
import { API } from "../../../../services/api";
import DatePicker from "react-datepicker";
import { StoreConsignmentProductsContext } from "../store_consignment_products";
import { UserContext } from "../../../../App";

axios.defaults.baseURL = API;

function CreateStoreConsignmentProducts(props) {
    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
    }

    function getExpiredate(gdate) {
        const today = gdate;
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        return `${year}-${month}-${date} ${time}`;
      }
    const [EndedDate, setEndedDate] = useState(null); 
    const filterPassedTime = time => {
        const selectedDate = new Date(time);
        return selectedDate.getTime();
      }
    
    const handleChangeEndedDate = event => {
        if (event){
            setEndedDate(event);
            handleChange("EndDate", event);
        }else {
            setEndedDate(null);
            handleChange("EndDate", "");
        }
    }

    const {showData, setShowData, SupplierList} = useContext(StoreConsignmentProductsContext);
    const {userID, LocationID} = useContext(UserContext);

    const handleSelectSuppler = (e) => {
        var data = SupplierList.filter(function (item) {
            return item.SupplierName == e.target.value
        })
        if (data.length > 0) {
            handleChange("SupplierName", data[0].SupplierName);
            handleChange("SupplierID", data[0].SupplierID);
        } else {
            handleChange("SupplierName", "");
            handleChange("SupplierID", "");
        }
    };

    async function saveTutorial() {
        CreateInventory();
        CreateConsignmentProduct();
        ClearData();
        props.onClick(false);

    };

    async function CreateInventory() {
        if(showData.length > 0) {
            for (var i=0; i < showData.length; i++) {
                var list;
                list ={
                    ProductID: showData[i].ProductID,
                    Quantity: showData[i].Quantity,
                    LocationID: LocationID,
                };
                await axios.get(`/getStoreInventory/${LocationID}/${showData[i].ProductID}`).then((response) => {
                    let Data = response?.data;
                    if(Data.length > 0){
                        var qty = Number(showData[i].Quantity) + Number(Data[0].Quantity);
                        UpdateInventory(Data[0].StoreInventoryID, qty);
                    } else {
                        CreateNewInventory(list);
                    }
                })
                  .catch((error) => {
                    console.log(error);
                });
            } 
        }
    }

    async function CreateConsignmentProduct() {
        if(showData.length > 0) {
            for (var i=0; i < showData.length; i++) {
                var list;
                list ={
                    SupplierID: values.SupplierID,
                    ProductID: showData[i].ProductID,
                    Quantity: showData[i].Quantity,
                    TotalQuantity: showData[i].Quantity,
                    LocationID: LocationID,
                    ExpiredDate: getExpiredate(EndedDate),
                    CreateByID: userID,
                    CreateDate: getDate()

                };
                await axios.get(`/getConsignmentProduct/${values.SupplierID}/${LocationID}/${showData[i].ProductID}/${getExpiredate(EndedDate)}`).then((response) => {
                    let Data = response?.data;
                    if(Data.length > 0){
                        
                        var qty = Number(showData[i].Quantity) + Number(Data[0].Quantity);
                        UpdateConsignmentProduct(Data[0].ConsignmentProductID, qty);
                       
                    } else {
                        CreateNewConsignmentProduct(list);
                    }
                })
                  .catch((error) => {
                    console.log(error);
                });
            } 
        }
    }

    async function UpdateInventory(StoreInventoryID, qty) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: qty
        }
        await axios.put(`/updateStoreInventory/${StoreInventoryID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    async function UpdateConsignmentProduct(ConsignmentProductID, qty) {
        let token = localStorage.getItem("token");
        var data = {
            Quantity: qty
        }
        await axios.put(`/updateConsignmentProduct/${ConsignmentProductID}`, data, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
            props.onClick(false);
        }).catch((err) => {
            console.log(err);
        });
    }

    async function CreateNewInventory(list) {
        let token = localStorage.getItem("token");
        await axios.post("/CreateStoreInventory", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }

    async function CreateNewConsignmentProduct(list) {
        let token = localStorage.getItem("token");
        await axios.post("/CreateConsignmentProduct", list, { headers: { Authorization: `MR_ADMIN ${token}`}}).then((response) => {
        }).catch((err) => {
            console.log(err);
        });
    }


    const ClearData = () => {
        localStorage.removeItem('purchaseOrderData');
        handleChange("SupplierName", "");
        handleChange("SupplierID", "");
        setShowData([]);
    }

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(saveTutorial, validate);

    return (
        <div style={{ display: props && props.show ? 'flex' : 'none' }} className="popUpForm">
            <div className="content">
                <div className="Header">
                    <span>ສ້າງຈຳນວນສິນຄ້າຝາກຂາຍ</span>
                </div>
                <div className="Body">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                            <div className="col form-group">
                                <label>ຜູ້ສະໜອງ: </label>
                                <select name="SupplierID" onChange={(e) => handleSelectSuppler(e)}
                                    className={`input form-control ${errors.SupplierID && 'is-invalid'}`}>
                                    <option
                                        key={""}
                                        value={""}
                                    >
                                        {'ເລືອກຜູ້ສະໜອງ'}
                                    </option>
                                    {   SupplierList && SupplierList.map(item => (
                                        <option
                                            key={item.SupplierID}
                                            value={item.SupplierName}
                                        >
                                            {item.SupplierName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col form-group">
                                <label>ມື້ຄົບກຳນົດ: </label>
                                <div className="EndedDate">
                                    <DatePicker className="date"
                                        selected={EndedDate}
                                        onChange={(e) => handleChangeEndedDate(e)}
                                        showTimeSelect
                                        filterTime={filterPassedTime}
                                        dateFormat="dd-MM-yyyy HH:mm"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <button type="submit" className="btn btn-primary">
                                ບັນທຶກ
                            </button>
                            <button type="button" onClick={(e) => props.onClick(false)} className="btn btn-danger" data-dismiss="modal" aria-label="Close">
                                ກັບຄືນ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateStoreConsignmentProducts;
