
function CreateStoreConsignmentProductsValidate(values) {

    let errors = {};

    if (!values.EndDate)
        errors.EndDate = 'PaymentTypeID is ExpiredDate';

    if (!values.SupplierID)
        errors.SupplierID = 'SupplierID is required';

    return errors;
};

export default CreateStoreConsignmentProductsValidate;
